/** @format */
import Metismenu from "metismenujs";
import React, { Component, useContext, useEffect, useState } from "react";
import PerfectScrollbar from "react-perfect-scrollbar";
import { Link } from "react-router-dom";
import { useScrollPosition } from "@n8tb1t/use-scroll-position";
import { ThemeContext } from "context/ThemeContext";
// import { getProviderProfile } from "store/actions/profile";
import { useDispatch } from "react-redux";
import MMLogo from "images/3MTT/fg.png";
import { Path } from "utils/constant.data";
import { Page } from "utils/constant.data";
import { setActiveRoom } from "store/reducers/UserReducer";
import { decrypt } from "utils/secure";

class MM extends Component {
  componentDidMount() {
    this.$el = this.el;
    this.mm = new Metismenu(this.$el);
  }
  componentWillUnmount() {}
  render() {
    return (
      <div className="mm-wrapper">
        <ul className="metismenu" ref={(el) => (this.el = el)}>
          {this.props.children}
        </ul>
      </div>
    );
  }
}

const SideBar = () => {
  const { iconHover, sidebarposition, headerposition, sidebarLayout } =
    useContext(ThemeContext);
  const _user = decrypt(window.localStorage.getItem("3MTAUSER"));
  const currentUser = JSON.parse(_user) ?? null;
  let dispatch = useDispatch();


  useEffect(() => {
    var btn = document.querySelector(".nav-control");
    var aaa = document.querySelector("#main-wrapper");
    function toggleFunc() {
      return aaa.classList.toggle("menu-toggle");
    }
    btn.addEventListener("click", toggleFunc);

    var handleheartBlast = document.querySelector(".heart");
    function heartBlast() {
      return handleheartBlast.classList.toggle("heart-blast");
    }
    handleheartBlast.addEventListener("click", heartBlast);
  }, []);

 

  //For scroll
  const [hideOnScroll, setHideOnScroll] = useState(true);
  useScrollPosition(
    ({ prevPos, currPos }) => {
      const isShow = currPos.y > prevPos.y;
      if (isShow !== hideOnScroll) setHideOnScroll(isShow);
    },
    [hideOnScroll]
  );

  const pathName = window.location.pathname;
  let path = pathName.split("/");
  path = path[path.length - 1];

  let deshBoard = ["dashboard"],
    fellows = ["fellows"],
    providers = ["providers"],
    cohorts = ["cohorts"],
    learning_tracker = ["tracker"],
    assesments = ["assesments"],
    // partners = ["partners"],
    // organsisations = ["organsisations"],
    users = ["users"],
    roles = ["roles-permisssions"],
    faqs = ["faqs"],
    email = ["email"],
    notifications = ["notifications"],
    webinar = ["webinar"],
    jobs = ["jobs"],
    surveys = ["surveys"],
    certificates = ["certificates"]
    ;

  const pathIncludesForum = pathName.includes("forum");

  const rooms = [
    {
      title: "LGAs",
      room_type: "Lga",
    },
    {
      title: "Courses",
      room_type: "Course",
    },
  ];

  const analytics = [
    {
      title: "3MTT Learners",
      path: Path.ADMIN_DASHBOARD
    },
    {
      title: "ALCs",
      path: Path.ADMIN_PROVIDER_ANALYTICS
    },
    {
      title: "Attendance",
      path: Path.ADMIN_ATTENDANCE_ANALYTICS
    }
  ]
  const courses = [
    {
      title: "All Courses",
      path: Path.ADMIN_ALL_COURSES,
    },
    {
      title: "Community Resource",
      path: Path.ADMIN_COMMUNITY_RESOURCES,
    },
    {
      title: "Online Learning Content",
      path: Path.ADMIN_LEARNING_CONTENT,
    },
    {
      title: "Course Modules",
      path: Path.ADMIN_COURSE_MODULES,
    },
  ];

  const broadcasts = [
    {
      title: "Broadcasts",
      path: Path.ADMIN_BROADCAST
    },
    {
      title: "Carousels",
      path: Path.ADMIN_CAROUSELS
    }

  ]

  const matches = [
    {
      title: "Matches",
      path: Path.ADMIN_MATCHING
    },
    {
      title: "Reports",
      path: Path.ADMIN_MATCHING_REPORTS
    },
  ]

  const __user = decrypt(window.localStorage.getItem("3MTAUSER"));
  const _currentUser = JSON.parse(__user) ?? null;
  const permissions = _currentUser?.role?.permissions?.map((_) => _?.slug);
  // console.log('permissions',permissions)


  return (
    <div
      className={`dlabnav ${iconHover} ${
        sidebarposition.value === "fixed" &&
        sidebarLayout.value === "horizontal" &&
        headerposition.value === "static"
          ? hideOnScroll > 120
            ? "fixed"
            : ""
          : ""
      }`}
    >
      <PerfectScrollbar className="dlabnav-scroll">
        <MM className="metismenu" id="menu">
        

          <li
            className={`course-link-item ${
              deshBoard.includes(path) ? "active-parent" : ""
            }`}
          >
            <Link className="has-arrow parent-link" to="#">
              <i className="bi bi-grid"></i>
              <span className="nav-text">Dashboard</span>
            </Link>
            <ul>
              {analytics?.map((sub) => {
                return (
                  <li key={sub?.path}>
                    <Link
                      className={`${
                        path === sub?.path ? "mm-active" : ""
                      } child-link`}
                      to={sub?.path}
                    >
                      {sub?.title}
                    </Link>
                  </li>
                );
              })}
            </ul>
          </li>
          {/* {permissions?.includes(Page.FORUM) && (
            <li
              className={`course-link-item ${
                pathIncludesForum ? "active-parent" : ""
              }`}
            >

              <Link className="has-arrow parent-link" to={Path.ADMIN_DASHBOARD}>
                <i className="bi bi-grid"></i>
                <span className="nav-text">Dashboard</span>
              </Link>
              <ul>
                {analytics?.map((item) => {
                  return (
                    <li key={item?.link}>
                      <Link
                        className={`${
                          path === "product-management" ? "mm-active" : ""
                        } child-link`}
                        to={`${
                          Path.ADMIN_FORUM
                        }/${item?.url?.toLowerCase()}`}
      
                      >
                        {item?.title}
                      </Link>
                    </li>
                  );
                })}
              </ul>
            </li>
          )} */}

          {permissions?.includes(Page.PROVIDERS) && (
            <li className={`${providers.includes(path) ? "mm-active" : ""}`}>
              <Link className="ai-icon" to={Path.ADMIN_PROVIDERS}>
                {" "}
                <i className="bi bi-people"></i>{" "}
                <span className="nav-text">Providers</span>
              </Link>
            </li>
          )}

          {permissions?.includes(Page.FELLOWS) && (
            <li className={`${fellows.includes(path) ? "mm-active" : ""}`}>
              <Link className="ai-icon" to={Path.ADMIN_FELLOWS}>
                {" "}
                <i className="bi bi-people"></i>{" "}
                <span className="nav-text">Fellows</span>
              </Link>
            </li>
          )}

          {permissions?.includes(Page.COHORTS) && (
            <li className={`${cohorts.includes(path) ? "mm-active" : ""}`}>
              <Link className="ai-icon" to={Path.ADMIN_COHORTS}>
                {" "}
                <i className="fa-solid bi-tags"></i>{" "}
                <span className="nav-text">Cohorts</span>
              </Link>
            </li>
          )}

          {permissions?.includes(Page.COURSES) && (
            <li
              className={`course-link-item ${
                pathIncludesForum ? "active-parent" : ""
              }`}
            >
              <Link className="has-arrow parent-link" to="#">
                <i className="bi bi-hdd-network"></i>
                <span className="nav-text">Courses</span>
              </Link>
              <ul>
                {courses?.map((_) => {
                  return (
                    <li key={_?.path}>
                      <Link
                        className={`${
                          path === "product-management" ? "mm-active" : ""
                        } child-link`}
                        to={_?.path}
                      >
                        {_?.title}
                      </Link>
                    </li>
                  );
                })}
              </ul>
            </li>
          )}

          {permissions?.includes(Page.LEARNING_TRACKER) && (
            <li
              className={`${
                learning_tracker.includes(path) ? "mm-active" : ""
              }`}
            >
              <Link className="ai-icon" to={Path.ADMIN_LEARNING_TRACKER}>
                {" "}
                <i className="bi bi-slash-circle"></i>{" "}
                <span className="nav-text">Learning Tracker</span>
              </Link>
            </li>
          )}

          {permissions?.includes(Page.COURSES) && (
            <li
              className={`course-link-item ${
                pathIncludesForum ? "active-parent" : ""
              }`}
            >
              <Link className="has-arrow parent-link" to="#">
                <i className="bi bi-puzzle"></i>{" "}
                <span className="nav-text">Matching</span>
                </Link>
                <ul>
              {matches?.map((sub) => {
                return (
                  <li key={sub?.path}>
                    <Link
                      className={`${
                        path === sub?.path ? "mm-active" : ""
                      } child-link`}
                      to={sub?.path}
                    >
                      {sub?.title}
                    </Link>
                  </li>
                );
              })}
            </ul>
            </li>
          )}

          {permissions?.includes(Page.ASSESMENT) && (
            <li className={`${assesments.includes(path) ? "mm-active" : ""}`}>
              <Link className="ai-icon" to={Path.ADMIN_ASSESMENTS}>
                {" "}
                <i className="bi bi-book"></i>{" "}
                <span className="nav-text">Assesments</span>
              </Link>
            </li>
          )}

          {(currentUser?.role?.slug === "super_admin" ||
            currentUser?.role?.slug === "software_developer") && (
            <>
              <li
                className={`course-link-item ${
                  users.includes(path) || roles.includes(path)
                    ? "active-parent"
                    : ""
                }`}
              >
                <Link className="has-arrow parent-link" to="#">
                  <i className="bi bi-hdd-network"></i>
                  <span className="nav-text">Users Management</span>
                </Link>
                <ul>
                  <li className={`${users.includes(path) ? "mm-activem" : ""}`}>
                    <Link className="ai-icon" to={Path.ADMIN_USERS}>
                      {" "}
                      <i className="bi bi-card-checklist"></i>{" "}
                      <span className="nav-text">Users</span>
                    </Link>
                  </li>
                  <li className={`${roles.includes(path) ? "mm-activem" : ""}`}>
                    <Link className="ai-icon" to={Path.ADMIN_ROLES}>
                      {" "}
                      <i className="bi bi-card-list"></i>{" "}
                      <span className="nav-text">Roles</span>
                    </Link>
                  </li>
                </ul>
              </li>
            </>
          )}


          {permissions?.includes(Page.BROADCAST) && (
            <li
              className={`course-link-item ${
                pathIncludesForum ? "active-parent" : ""
              }`}
            >
              
              <Link className="has-arrow parent-link" to="#">
                <i className="bi bi-bullseye"></i>{" "}
                <span className="nav-text">Broadcasts</span>
                </Link>
                <ul>
              {broadcasts?.map((sub) => {
                return (
                  <li key={sub?.path}>
                    <Link
                      className={`${
                        path === sub?.path ? "mm-active" : ""
                      } child-link`}
                      to={sub?.path}
                    >
                      {sub?.title}
                    </Link>
                  </li>
                );
              })}
            </ul>
            </li>
          )}

          {permissions?.includes(Page.NOTIFICATION) && (
            <li
              className={`${notifications.includes(path) ? "mm-active" : ""}`}
            >
              <Link className="ai-icon" to={Path.ADMIN_NOTIFICATIONS}>
                <i className="bi bi-bell"></i>
                <span className="nav-text">Notifications</span>
              </Link>
            </li>
          )}

          {permissions?.includes(Page.EMAIL) && (
            <li className={`${email.includes(path) ? "mm-active" : ""}`}>
              <Link className="ai-icon" to={Path.ADMIN_EMAILS}>
                <i className="bi bi-envelope"></i>
                <span className="nav-text">Emails</span>
              </Link>
            </li>
          )}

          {permissions?.includes(Page.FORUM) && (
            <li
              className={`course-link-item ${
                pathIncludesForum ? "active-parent" : ""
              }`}
            >
              <Link className="has-arrow parent-link" to="#">
                <i className="bi bi-book"></i>
                <span className="nav-text">Forums</span>
              </Link>
              <ul>
                {rooms?.map((room) => {
                  return (
                    <li key={room?.room_type}>
                      <Link
                        className={`${
                          path === "product-management" ? "mm-active" : ""
                        } child-link`}
                        to={`${
                          Path.ADMIN_FORUM
                        }/${room?.room_type?.toLowerCase()}`}
                        onClick={() => {
                          dispatch(
                            setActiveRoom(room?.room_type?.toLowerCase())
                          );
                        }}
                      >
                        {room?.title}
                      </Link>
                    </li>
                  );
                })}
              </ul>
            </li>
          )}

          {permissions?.includes(Page.JOBS) && (
            <li className={`${jobs.includes(path) ? "mm-active" : ""}`}>
              <Link className="ai-icon" to={Path.ADMIN_JOBS}>
                <i className="bi bi-basket3"></i>
                <span className="nav-text">Jobs & Gigs</span>
              </Link>
            </li>
          )}

          {permissions?.includes(Page.SURVEYS) && (
            <li className={`${surveys.includes(path) ? "mm-active" : ""}`}>
              <Link className="ai-icon" to={Path.ADMIN_SURVEYS}>
                <i className="bi bi-question-square"></i>
                <span className="nav-text">Surveys</span>
              </Link>
            </li>
          )}

          {permissions?.includes(Page.SURVEYS) && (
            <li className={`${certificates.includes(path) ? "mm-active" : ""}`}>
              <Link className="ai-icon" to={Path.ADMIN_CERTIFICATES}>
                <i className="bi bi-patch-check"></i>
                <span className="nav-text">Certificates</span>
              </Link>
            </li>
          )}

          {permissions?.includes(Page.WEBINAR) && (
            <li className={`${webinar.includes(path) ? "mm-active" : ""}`}>
              <Link className="ai-icon" to={Path.ADMIN_WEBINAR}>
                <i className="bi bi-grid"></i>
                <span className="nav-text">Webinars</span>
              </Link>
            </li>
          )}

          {permissions?.includes(Page.FAQ) && (
            <li className={`${faqs.includes(path) ? "mm-active" : ""}`}>
              <Link className="ai-icon" to={Path.ADMIN_FAQS}>
                <i className="bi bi-bricks"></i>
                <span className="nav-text">FAQs</span>
              </Link>
            </li>
          )}

          {/* 
          <li className={`${partners.includes(path) ? "mm-active" : ""}`}>
            <Link className="ai-icon" to={Path.ADMIN_PARTNERS}>
              <i className="bi bi-grid"></i>
              <span className="nav-text">Partkkkners</span>
            </Link>
          </li>

          <li className={`${organsisations.includes(path) ? "mm-active" : ""}`}>
            <Link className="ai-icon" to={Path.ADMIN_ORGANISATIONS}>
              <i className="bi bi-person"></i>
              <span className="nav-text">Organizations</span>
            </Link>
          </li>

          <li className={`${user.includes(path) ? "mm-active" : ""}`}>
            <Link className="ai-icon" to={Path.ADMIN_USER_MANAGEMENT}>
              {" "}
              <i className="bi bi-people"></i>{" "}
              <span className="nav-text">User Management</span>
            </Link>
          </li> */}
        </MM>

        <Link to={Path.ADMIN_DASHBOARD} className="ministry-logo">
          <figure>
            <img src={MMLogo} alt="" />
          </figure>
        </Link>

        <div className="copyright mt-5">
          <p>
            <b>FMoCIDE</b> © {new Date().getFullYear()} All Rights Reserved
          </p>
          <p className="fs-12 text-center">
            <span className="heart" style={{ display: "none" }}></span>
          </p>
        </div>
      </PerfectScrollbar>
    </div>
  );
};

export default SideBar;
