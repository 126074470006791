/** @format */
import * as Yup from "yup";

export const replaceUnderscoresWithSpaces = (inputString) => {
  return inputString ? inputString.replace(/_/g, " ") : "";
};

export const capitalize = (value) => {
  if (typeof value !== "string") return "";
  const words = value?.toLocaleLowerCase().trim().split(" ");
  return words
    .map((word) =>
      word?.length === 0 ? word : word[0].toUpperCase() + word.substring(1)
    )
    .join(" ");
};

export const capitalizeSentences = (text) => {
  // Split the text into an array of sentences using a regular expression
  const sentences = text.split(/(?<=[.!?])\s+/);

  // Capitalize the first letter of each sentence
  const capitalizedSentences = sentences.map((sentence) => {
    // Trim the sentence to remove leading spaces
    sentence = sentence.trim();

    // Capitalize the first letter of the sentence
    if (sentence.length > 0) {
      sentence = sentence.charAt(0).toUpperCase() + sentence.slice(1);
    }

    return sentence;
  });

  // Join the sentences back together
  return capitalizedSentences.join(" ");
};

export const formatUnderScore = (value) => {
  let data = value?.toString();
  let newvalue = data?.replaceAll("_", " ");
  return value ? capitalize(newvalue) : "";
};

export const removeSpaces = (inputString) => {
  const wordsArray = inputString.split(" ");
  const stringWithoutSpaces = wordsArray.join("");
  return stringWithoutSpaces;
};

export const formatHyphen = (value) => {
  let data = value?.toString();
  let newvalue = data?.replaceAll("-", " ");
  return value ? capitalize(newvalue) : "";
};

export const replaceSpace = (value) => {
  let data = value?.toString();
  let newvalue = data?.replaceAll(" ", "_");
  return value ? capitalize(newvalue) : "";
};

export const mapStatusClass = (option) => {
  let res = "";
  let _option = option.toLowerCase();

  switch (_option) {
    case "new":
      res = "new";
      break;
    case "application_incomplete":
      res = "incomplete";
      break;
    case "application_approved":
      res = "approved";
      break;
    case "selected":
      res = "selected";
      break;
    case "shortlisted":
      res = "shortlisted";
      break;
    case "blacklisted":
      res = "blacklist";
      break;
    case "admitted":
      res = "admitted";
      break;
    case "application_completed":
      res = "primary";
      break;
    case "enrolled":
      res = "enrolled";
      break;
    case "in_training":
      res = "training";
      break;
    case "certified":
      res = "certified";
      break;
    case "incomplete_alc_training":
      res = "incomplete_alc_training";
      break;
    case "completed_alc_training":
      res = "completed_alc_training";
      break;
    case "inactive":
      res = "inactive";
      break;
    case "employed":
    case "placed":
    case "deployed":
      res = "employed";
      break;
    case "failed":
      res = "inactive"
      break;
    case "in_progress":
      res = "incomplete_alc_training"
      break;
    case "completed":
      res = "selected"
      break;
    default:
      res = "";
      break;
  }

  return res;
};

export const mapStatusHeading = (option) => {
  let res = "";
  let _option = option.toLowerCase();

  switch (_option) {
    case "new":
      res = "New";
      break;
    case "application_incomplete":
      res = "Application Incomplete";
      break;
    case "lc_member":
      res = "LC Member";
      break;
    case "selected":
      res = "Selected";
      break;
    case "shortlisted":
      res = "Shortlisted";
      break;
    case "waitlisted":
      res = "Waitlisted";
      break;
    case "admitted":
      res = "Admitted";
      break;
    case "application_completed":
      res = "Application Completed";
      break;
    case "application_approved":
      res = "Application Approved";
      break;
    case "enrolled":
      res = "Enrolled";
      break;
    case "in_training":
      res = "Training";
      break;
    case "certified":
      res = "Certified";
      break;
    case "blacklisted":
      res = "Blacklisted";
      break;
    case "employed":
    case "placed":
    case "deployed":
      res = "Employed";
      break;
    case "incomplete_alc_training":
      res = "Incomplete ALC Training";
      break;
    case "completed_alc_training":
      res = "Completed ALC Training";
      break;
    case "inactive":
      res = "Inactive";
      break;
    case "failed":
      res = "Failed"
      break;
    case "in_progress":
      res = "In progress"
      break;
    case "completed":
      res = "Completed"
      break;
    default:
      res = "";
      break;
  }

  return res;
};

export const mapStatus = (option) => {
  let res = "";
  let _option = option.toLowerCase();

  switch (_option) {
    case "new":
    case "application_incomplete":
      res = "btn-light";
      break;
    case "application_completed":
      res = "btn-warning";
      break;
    case "lc_member":
      res = "btn-primary";
      break;
    case "shortlisted":
      res = "btn-primary";
      break;
    case "admitted":
      res = "Admitted";
      break;
    case "selected":
      res = "btn-info";
      break;
    case "in_training":
      res = "Training";
      break;
    case "certified":
      res = "Certified";
      break;
    case "employed":
    case "placed":
    case "deployed":
      res = "Employed";
      break;
    default:
      res = "";
      break;
  }

  return res;
};

export const mapStatusInformation = (option) => {
  let res = "";
  let _option = option.toLowerCase();

  switch (_option) {
    case "new":
    case "application_incomplete":
      res = "Your application will not be reviewed until completed.";
      break;
    case "lc_member":
      res = "You are a member of our learning commnuity";
      break;
    case "selected":
      res =
        "Congratulations, you have been accepted as a training porvider for this phase.";
      break;
    case "waitlisted":
      res =
        "You are now on the training provider waitllist. You will be notified in the next phase of the training.";
      break;
    case "rejected":
      res =
        "You have not been selected as a training provider. You can re-apply in the next phase.";
      break;
    case "shortlisted":
      res = "Shortlisted";
      break;
    case "admitted":
      res = "Admitted";
      break;
    case "application_completed":
      res = "Your application is complete and under review.";
      break;
    case "enrolled":
      res = "Enrolled";
      break;
    case "in_training":
      res = "Training";
      break;
    case "certified":
      res = "Certified";
      break;
    case "deactivated":
      res = "Deactivated";
      break;
    case "employed":
    case "placed":
    case "deployed":
      res = "Employed";
      break;
    default:
      res = "";
      break;
  }

  return res;
};

export const Colors = {
  polyGreen: "#29562a",
  pigmentGreen: "#26a65b",
  yellowGreen: "#b2d235",
  peachYellow: "#F9DCAD",
  lemon: "#0cf2df",
  aliceBlue: "#dff4fe",
  darkGray: "#2c3e4b",
  gray3: "#808b93",
  gray2: "#d4d8db",
  gray1: "#f8f9fa",
};

export const AgeOptions = [
  { value: "", label: "Select Age Range" },
  { value: "< 18", label: "Below 18" },
  { value: "18-25", label: "18 - 25 years" },
  { value: "26-35", label: "26 - 35 years" },
  { value: "36-45", label: "36 - 45 years" },
  { value: "> 45", label: "Above 45" },
];

export const GenderOptions = [
  { value: "", label: "Select Gender" },
  { value: "Male", label: "Male" },
  { value: "Female", label: "Female" },
];

export const LearningProgressOptions = [
  { value: "", label: "Select Learning Progress" },
  { value: "0-10", label: "0-10" },
  { value: "11-20", label: "11-20" },
  { value: "21-30", label: "21-30" },
  { value: "31-40", label: "31-40" },
  { value: "41-50", label: "41-50" },
  { value: "51-60", label: "51-60" },
  { value: "61-70", label: "61-70" },
  { value: "71-80", label: "71-80" },
  { value: "81-90", label: "81-90" },
  { value: "91-100", label: "91-100" },
  { value: "100-100", label: "100" },
];


export const SkillOptions = [
  { value: "", label: "Select Skill Level" },
  {
    value: "Beginner level (Zero 0 or less than 1year experience)",
    label: "Beginner level (Zero 0 or less than 1 year experience)",
  },
  {
    value: "Intermediate level (1 to 4 years experience)",
    label: "Intermediate level (1 to 4 years experience)",
  },
  {
    value: "Advanced level (4+ years of experience)",
    label: " Advanced level (5+ years of experience)",
  },
];

export const StatusOptions = [
  { value: "", label: "Select Status" },
  { value: "New", label: "New" },
  { value: "Selected", label: "Selected" },
  { value: "Shortlisted", label: "Shortlisted" },
  // { value: "Admitted", label: "Admitted" },
  { value: "Enrolled", label: "Enrolled" },
  { value: "In Training", label: "In Training" },
  { value: "Certified", label: "Certified" },
  { value: "Employed", label: "Employed" },
];

export const ProviderOptions = [
  // { value: "", label: "Select Status" },
  { value: "New", label: "New" },
  { value: "Selected", label: "Selected" },
  { value: "application_incomplete", label: "Application Incomplete" },
  { value: "application_completed", label: "Application Completed" },
  { value: "application_approved", label: "Application Approved" },
  { value: "Blacklisted", label: "Blacklisted" },
];


export const AssesmentStatus = [
  { value: "approved", label: "Approved" },
  { value: "declinced", label: "Declinced" },
  { value: "submitted", label: "Submitted" },
];

export const FellowOptions = [
  { value: "", label: "Select Status" },
  { value: "New", label: "New" },
  { value: "Selected", label: "Selected" },
  { value: "application_completed", label: "Application Completed" },
  { value: "completed_alc_training", label: "Completed ALC Training" },
  { value: "Inactive", label: "Inactive" },
  { value: "Employed", label: "Employed" },
  { value: "incomplete_alc_training", label: "Incomplete ALC Training" },
  { value: "Certified", label: "Certified" },
 
];

// completed_alc_training, Inactive, incomplete_alc_training




export const API_TAGS = {
  FETCH_PROVIDER_FELLOWS: "FETCH_PROVIDER_FELLOWS",
  FETCH_PROVIDER_INFO: "FETCH_PROVIDER_INFO",
  FETCH_COURSE_MODULES: "FETCH_COURSE_MODULES",
  FETCH_FELLOW_INFO: "FETCH_FELLOW_INFO",
  FETCH_FELLOW_TEST: "FETCH_FELLOW_TEST",
  FETCH_FELLOW_QUESTIONS: "FETCH_FELLOW_QUESTIONS",
  FETCH_FELLOW_PERKS: "FETCH_FELLOW_PERKS",
  UPDATE_PROVIDER_PROFILE: "UPDATE_PROVIDER_PROFILE",
  UPDATE_FELLOW_COURSE: "UPDATE_FELLOW_COURSE",
  SUBMIT_FELLOW_TEST: "SUBMIT_FELLOW_TEST",
  SUBMIT_FELLOW_QUESTIONS: "SUBMIT_FELLOW_QUESTIONS",
  FETCH_ADMIN_FORUMS: "FETCH_ADMIN_FORUMS",
  FETCH_ADMIN_MESSAGES: "FETCH_ADMIN_MESSAGES",
  CREATE_WEBINAR: "CREATE_WEBINAR",
  FETCH_WEBINARS: "FETCH_WEBINARS",
  UPDATE_WEBINAR: "UPDATE_WEBINAR",
  DELETE_WEBINAR: "DELETE_WEBINAR",
  BULK_ASSIGN_COHORT:"BULK_ASSIGN_COHORT",


  CREATE_EPISODE: "CREATE_EPISODE",
  FETCH_EPISODES: "FETCH_EPISODES",
  UPDATE_EPISODE: "UPDATE_EPISODE",
  DELETE_EPISODE: "DELETE_EPISODE",

  FETCH_PROVIDER_BROADCASTS: "FETCH_PROVIDER_BROADCASTS",
  FETCH_FELLOW_NOTIFICATIONS: "FETCH_FELLOW_NOTIFICATIONS",
  UPDATE_FELLOW_NOTIFICATION: "UPDATE_FELLOW_NOTIFICATION",
  DELETE_BROADCAST: "DELETE_BROADCAST",
  CREATE_BROADCAST: "CREATE_BROADCAST",

  FETCH_FAQ_TYPES: "FETCH_FAQ_TYPES",
  FETCH_FAQS: "FETCH_FAQS",
  CREATE_FAQ: "CREATE_FAQ",
  UPDATE_FAQS: "UPDATE_FAQS",
  DELETE_FAQS: "DELETE_FAQS",

  SEND_EMAIL: "SEND_EMAIL",
  BULK_ASSIGN_STATUS: "BULK_ASSIGN_STATUS",
  BULK_UPDATE_STATUS: "BULK_UPDATE_STATUS",
  GET_ALL_FELLOWS: "GET_ALL_FELLOWS",

  BULK_PROVIDER_UPDATE_STATUS:"BULK_PROVIDER_UPDATE_STATUS",
  GET_ALL_PROVIDERS:"GET_ALL_PROVIDERS",

  GET_ALL_COHORTS:"GET_ALL_COHORTS",
  GET_ALL_USERS:"GET_ALL_USERS",
  GET_ALL_PENDING_USERS:"GET_ALL_PENDING_USERS",
  UPDATE_ADMIN_USER:"UPDATE_ADMIN_USER",

  GET_ALL_ROLES:"GET_ALL_ROLES",
  GET_ALL_PERMISSIONS:"GET_ALL_PERMISSIONS",
  CREATE_ROLE:"CREATE_ROLE",
  UPDATE_ROLE:"UPDATE_ROLE",
  DELETE_ROLE:"DELETE_ROLE",
  SEND_USER_INVITE:"SEND_USER_INVITE",

  CREATE_COMMUNITY_RESOURCE: "CREATE_COMMUNITY_RESOURCE",
  FETCH_COMMUNITY_RESOURCES: "FETCH_COMMUNITY_RESOURCES",
  UPDATE_COMMUNITY_RESOURCE: "UPDATE_COMMUNITY_RESOURCE",

  CREATE_LEARNING_CONTENT: "CREATE_LEARNING_CONTENT",
  FETCH_LEARNING_CONTENTS: "FETCH_LEARNING_CONTENTS",
  UPDATE_LEARNING_CONTENT: "UPDATE_LEARNING_CONTENT",

  GET_ALL_SURVEYS: "GET_ALL_SURVEYS",

  GET_ALL_FELLOW_ONLINE_CERTIFICATES: "GET_ALL_FELLOW_ONLINE_CERTIFICATES",

  CREATE_CAROUSEL_BROADCAST: "CREATE_CAROUSEL_BROADCAST",
  FETCH_CAROUSEL_BROADCASTS: "FETCH_CAROUSEL_BROADCASTS",
  DELETE_CAROUSEL_BROADCAST: "DELETE_CAROUSEL_BROADCAST",
  UPDATE_CAROUSEL_BROADCAST: "UPDATE_CAROUSEL_BROADCAST"
};

export const truncateString = (str, maxLength = 30) => {
  if (str.length > maxLength) {
    return str.substring(0, maxLength) + "...";
  } else {
    return str;
  }
};

export const mapLevel = (option) => {
  let res = "";
  let _option = option.toLowerCase();

  switch (_option) {
    case "Beginner Level (zero 0 Or Less Than 1year Experience)".toLowerCase():
    case "Beginner level (Zero 0 or less than 1 year experience)".toLowerCase():
      res = "Beginner";
      break;
    case "Intermediate Level (1 To 4 Years Experience)".toLowerCase():
    case "Intermediate".toLowerCase():
      res = "Intermediate";
      break;
    case "Advanced Level (4+ Years Of Programming Experience)".toLowerCase():
    case "Advanced level (4+ years of experience)".toLowerCase():
      res = "Advanced";
      break;
    default:
      res = "";
      break;
  }

  return res;
};

export const countWords = (sentence) => {
  const words = sentence.split(/\s+/);
  const filteredWords = words.filter((word) => word.trim() !== "");
  return filteredWords.length;
};

export const wordCountValidator = (maxWords) => {
  return Yup.string().test(
    "wordCount",
    `Maximum ${maxWords} words allowed.`,
    (value) => {
      if (!value) return true;

      const wordArray = value.trim().split(/\s+/);
      return wordArray.length <= maxWords;
    }
  );
};

export const _listItem = [
  {
    title: "3MTT Home page ",
    link: "https://3mtt.nitda.gov.ng/",
  },
  {
    title: "Requirement document for training providers",
    link: "https://drive.google.com/file/d/1D1_SRWlBM6kR_XyVGGuPhbFRp25TuCUv/view",
  },

  {
    title: "Technical Talent Training Framework",
    link: "https://b.link/1MTTReport",
  },
  {
    title:
      "Strategic Blueprint for Federal Ministry of Communications, Innovation and Digital Economy",
    link: "http://b.link/Strategic-FMoCIDE",
  },
];

// export const extractErrorMessage = (errors) => {
//   let errorMessages = "";

//   for (const field in errors) {
//     errorMessages += `${capitalize(replaceUnderscoresWithSpaces(field))}: ${errors[
//       field
//     ].join(", ")}`;
//   }
//   return errorMessages;
// };

export const extractErrorMessage = (errors) => {
  let errorMessages = "";

  if (errors?.data?.errors) {
    for (const field in errors?.data?.errors) {
      errorMessages += `${replaceUnderscoresWithSpaces(field)}: ${errors?.data.errors[field].join(", ")}`;
    }
    return errorMessages
  }
  else if (errors?.data?.code) {
    errorMessages = errors?.data?.detail
    return errorMessages
  }
  else {
    errorMessages = errors?.data?.message
    return errorMessages
  }

};
