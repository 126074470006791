/** @format */

import React, { useMemo, useEffect, useState } from "react";
import {
  useTable,
  useGlobalFilter,
  useFilters,
  useSortBy,
  usePagination,
} from "react-table";
import "./helpers/filtering.css";
import { ReactComponent as BagIcon } from "images/svg/bag.svg";
import { Dropdown } from "react-bootstrap";
import { capitalize, truncateString } from "utils/strings";
import { formatDate } from "utils/date";
import Swal from "sweetalert2";
import { toast } from "react-toastify";
import { replaceUnderscoresWithSpaces } from "utils/strings";
import { ColumnFilter } from "./helpers/Columns/ColumnFilter";
import { useDeleteBroadcastCarouselMutation } from "store/services/adminAPI";
import { extractErrorMessage } from "utils/strings";
import UpdateCarousel from "../components/UpdateCarousel";

const AdminCarouselTable = ({
  carousels,
  metadata,
  setFilter,
  setSkip,
  messageClass,
}) => {
  useEffect(() => {
    setSkip(true);
  }, [carousels, setSkip]);

  const BROADCAST_COLUMNS = [
    {
      Header: "Title",
      accessor: "title",
      Filter: ColumnFilter,
      disableFilters: true,
      sortable: false,
      Cell: ({ cell }) => {
        return `${capitalize(cell?.row?.original?.title)}`;
      },
    },
    {
      Header: "Recipient",
      accessor: "recipient_type",
      Filter: ColumnFilter,
      disableFilters: true,
      sortable: false,
    },
    {
      Header: "Link",
      accessor: "link",
      Filter: ColumnFilter,
      disableFilters: true,
      sortable: false,
      Cell: ({ cell }) => {
        return cell?.row?.original?.link ? <a className="text-primary" href={cell?.row?.original?.link }>{truncateString(cell?.row?.original?.link)}</a> : <p>-</p>
      },
    },
    {
      Header: "Time",
      accessor: "created_at",
      Filter: ColumnFilter,
      disableFilters: true,
      sortable: false,
      Cell: ({ cell }) => {
        return `${capitalize(formatDate(cell?.row?.original?.created_at))}`;
      },
    },

    {
      Header: "Actions",
      disableSortBy: true,
      sortable: false,
      accessor: "",
      Filter: ColumnFilter,
      disableFilters: true,
      Cell: ({ cell }) => {
        const [showUpdateCarousel, setShowUpdateCarousel] = useState(false);

        const { id, title, message, link, recipient_type, target_cohorts, target_statuses, target_courses } =
          cell?.row?.original;

        const [deleteBroadcastCarousel] = useDeleteBroadcastCarouselMutation();

        return (
          <>
            <UpdateCarousel
              showUpdateCarousel={showUpdateCarousel}
              id={id}
              title = {title}
              message={message}
              link ={link}
              recipient_type = {recipient_type}
              target_statuses = {target_statuses}
              target_cohorts = {target_cohorts}
              target_courses = {target_courses}
              setShowUpdateCarousel = {setShowUpdateCarousel}

            />
              
            <Dropdown className="dropdown ms-auto text-right">
              <Dropdown.Toggle
                variant=""
                className="btn-link i-false"
                data-toggle="dropdown"
              >
                <svg
                  width="24px"
                  height="24px"
                  viewBox="0 0 24 24"
                  version="1.1"
                >
                  <g
                    stroke="none"
                    strokeWidth={1}
                    fill="none"
                    fillRule="evenodd"
                  >
                    <rect x={0} y={0} width={24} height={24} />
                    <circle fill="#000000" cx={5} cy={12} r={2} />
                    <circle fill="#000000" cx={12} cy={12} r={2} />
                    <circle fill="#000000" cx={19} cy={12} r={2} />
                  </g>
                </svg>
              </Dropdown.Toggle>
              <Dropdown.Menu className="dropdown-menu dropdown-menu-right">
                <Dropdown.Item onClick={() => setShowUpdateCarousel(true)}>
                  Update
                </Dropdown.Item>
               
                <Dropdown.Item
                  onClick={async () =>
                    Swal.fire({
                      title: "Notice",
                      iconHtml: ` <i class="fa fa-exclamation-triangle text-danger" aria-hidden="true"></i>`,
                      text: "Are you sure you want to delete this carousel?",
                      showCloseButton: true,
                      customClass: {
                        icon: "no-border",
                      },
                      cancelButtonText: "Delete",
                      showCancelButton: true,
                      confirmButtonText: "Cancel",
                      allowOutsideClick: false,
                      allowEscapeKey: false,
                    }).then(async (result) => {
                      if (!result.isConfirmed) {
                        await deleteBroadcastCarousel(id)
                          .unwrap()
                          .then((data) => {
                            setSkip(false);
                            toast.success(`✔️ ${data?.message} !`, {
                              position: "top-right",
                              autoClose: 2500,
                              hideProgressBar: false,
                              closeOnClick: true,
                            });
                          })
                          .catch((error) => {
                            const formattedError = extractErrorMessage(error)
                            Swal.fire({
                              title:
                                replaceUnderscoresWithSpaces(
                                  error?.data?.code
                                ) || "Error Occured",
                              text: formattedError,
                              iconHtml: ` <i class="fa fa-exclamation-triangle text-danger" aria-hidden="true"></i>`,
                              showCloseButton: true,
                              customClass: {
                                icon: "no-border",
                              },
                              confirmButtonText: "Try Again",
                            });
                          });
                      }
                    })
                  }
                >
                  Delete
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </>
        );
      },
    },
  ];

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const columns = useMemo(() => BROADCAST_COLUMNS, []);
  const data = useMemo(() => carousels, [carousels]);

  const tableInstance = useTable(
    { columns, data, initialState: { pageIndex: 0 } },
    useFilters,
    useGlobalFilter,
    useSortBy,
    usePagination
  );

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    tableInstance;

  const hasNext = metadata?.next > 0;
  const hasPrev = metadata?.previous > 0;

  return (
    <>
      <div className="card">
        <div className="card-header">
          <h4 className="card-title text-pigment-green">
            All Carousels
          </h4>
        </div>
        <div className="card-body">
          {data?.length > 0 ? (
            <div className="table-responsive">
              <div className="dataTables_wrapper">
                <table {...getTableProps()} className="table dataTable display">
                  <thead>
                    {headerGroups.map((headerGroup) => (
                      <tr {...headerGroup.getHeaderGroupProps()}>
                        {headerGroup.headers.map((column) => (
                          <th
                            {...column.getHeaderProps(
                              column.getSortByToggleProps()
                            )}
                          >
                            {column.render("Header")}
                          </th>
                        ))}
                      </tr>
                    ))}
                  </thead>
                  <tbody {...getTableBodyProps()}>
                    {rows.map((row) => {
                      prepareRow(row);
                      return (
                        <tr {...row.getRowProps()}>
                          {row.cells.map((cell) => {
                            return (
                              <td {...cell.getCellProps()}>
                                {" "}
                                {cell.render("Cell")}{" "}
                              </td>
                            );
                          })}
                        </tr>
                      );
                    })}
                  </tbody>
                </table>

                <div className="d-flex text-center justify-content-between align-items-center mt-3 mb-md-0 mb-2">
                  <button
                    className={`btn btn-outline ${hasPrev ? "" : "disabled"} `}
                    onClick={() => {
                      const _newPage = Number(metadata?.page - 1) || 1;
                      setFilter(
                        `page=${_newPage}&message_class=${messageClass}`
                      );
                    }}
                  >
                    Previous
                  </button>

                  {metadata?.count && (
                    <div className="d-flex">
                      <p className="mb-0">
                        Page <strong>{metadata?.page}</strong> of{" "}
                        <strong>{Math.ceil(metadata?.count / 100)}</strong>{" "}
                        &nbsp;
                      </p>
                      <p className="mb-0">
                        Total <strong>{metadata?.count}</strong>
                      </p>
                    </div>
                  )}

                  <button
                    className={`btn btn-outline ${hasNext ? "" : "disabled"} `}
                    onClick={() => {
                      const _newPage = Number(metadata?.page || 0) + 1;
                      setFilter(
                        `page=${_newPage}&message_class=${messageClass}`
                      );
                    }}
                  >
                    Next
                  </button>
                </div>
              </div>
            </div>
          ) : (
            <div className="card">
              <div className="card-body text-center ai-icon  text-primary">
                <BagIcon />
                <h4 className="my-2">No carousels for the specified filter</h4>
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default AdminCarouselTable;
